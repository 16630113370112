import React, { useState } from 'react'
import debounce from 'lodash/debounce'
import Fuse from 'fuse.js'
import {
  styled,
  withStyles,
  Avatar as MuiAvatar,
  Dialog as MuiDialog,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  IconButton as MuiIconButton,
  List as MuiList,
  ListItem as MuiListItem,
  ListItemAvatar as MuiListItemAvatar,
  ListItemText as MuiListItemText,
  TextField as MuiTextField
} from '@material-ui/core'
import MuiCloseIcon from '@material-ui/icons/Close'
import MuiPersonIcon from '@material-ui/icons/Person'
import { useEditApplication } from 'contentPublisher/hooks'
import { useUsers } from 'users/hooks'

const Dialog = styled(MuiDialog)({})

const CloseButton = styled(MuiIconButton)({
  position: 'absolute',
  top: 5,
  right: 5
})

const DialogContent = styled(MuiDialogContent)({})

const List = styled(MuiList)(({ theme }) => ({
  marginTop: theme.spacing(1),
  height: 500,
  overflow: 'auto',
  border: `1px solid ${theme.palette.grey.A200}`
}))

const ListItem = styled(MuiListItem)({})

const ListItemText = withStyles({
  primary: {
    fontSize: 14
  },
  secondary: {
    fontSize: 12
  }
})(MuiListItemText)

const IconAvatar = styled(MuiAvatar)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.light
}))

const Email = styled('span')(({ theme }) => ({
  color: theme.palette.primary.main
}))

//const nameSorter = (a, b) => a.name.localeCompare(b.name)

const fuseOptions = {
  id: 'id',
  shouldSort: true,
  threshold: 0.25,
  keys: ['firstName', 'lastName', 'companyName']
}

const AddDialog = ({ onClose, ...props }) => {
  const [application, updateApplication] = useEditApplication()
  const { data } = useUsers()
  const [search, setSearch] = useState('')

  const appAdmins = application.admins || []

  const users = data.allIds.map(id => data.byId[id])

  const fuse = new Fuse(users, fuseOptions)
  const searchResults = fuse.search(search)

  const filteredUsers = users.filter(u =>
    searchResults.some(searchResult => searchResult.item._id === u.id)
  )

  const updateSearch = debounce(v => setSearch(v), 500)

  const addAdmin = id => {
    const set = new Set(appAdmins)
    set.add(id)
    updateApplication({ admins: Array.from(set) })
  }

  let emptyMessage
  if (search.length === 0) {
    emptyMessage = (
      <ListItem disabled={true}>
        <ListItemText primary='Enter a search string to find users' />
      </ListItem>
    )
  } else if (filteredUsers.length === 0) {
    emptyMessage = (
      <ListItem disabled={true}>
        <ListItemText primary='No users match search string' />
      </ListItem>
    )
  }

  return (
    <Dialog fullWidth scroll='body' onClose={onClose} {...props}>
      <CloseButton onClick={onClose}>
        <MuiCloseIcon />
      </CloseButton>
      <MuiDialogTitle>Users</MuiDialogTitle>
      <DialogContent>
        <MuiTextField
          label='Search'
          fullWidth
          defaultValue={search}
          onChange={e => updateSearch(e.target.value)}
        />
        <List>
          {filteredUsers.map(user => {
            const fullName = `${user.firstName} ${user.lastName}`
            const email = `<${user.email}>`

            const avatar = user.avatar ? (
              <MuiAvatar src={user.avatar.uri} />
            ) : (
              <IconAvatar>
                <MuiPersonIcon />
              </IconAvatar>
            )

            const disabled = appAdmins.includes(user.id)

            return (
              <ListItem
                key={user.id}
                button
                onClick={() => addAdmin(user.id)}
                disabled={disabled}
              >
                <MuiListItemAvatar>{avatar}</MuiListItemAvatar>
                <ListItemText
                  primary={
                    <>
                      {fullName} <Email>{email}</Email>
                    </>
                  }
                  secondary={user.companyName}
                />
              </ListItem>
            )
          })}
          {emptyMessage}
        </List>
      </DialogContent>
    </Dialog>
  )
}

export default AddDialog
