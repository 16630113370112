import React from 'react'
import { styled } from '@material-ui/core'
import { useDurationState, useEditApplication } from 'contentPublisher/hooks'
import Checkbox from './Checkbox'

const Content = styled('div')({})

const Heading = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  margin: 0,
  marginTop: 24,
  marginBottom: 6,
  borderBottom: `1px solid ${theme.palette.divider}`,
}))

const Title = styled('h4')(({ theme }) => ({
  margin: 0,
  fontSize: 18,
  fontWeight: 500,
  color: theme.palette.text.secondary,
}))

const Grid = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
})

//const nameSorter = (a, b) => a.name.localeCompare(b.name)

const Durations = props => {
  const [application, updateApplication] = useEditApplication()
  const { data } = useDurationState()

  // const allDurations = Object.values(data.byId).sort(nameSorter)
  const allDurations = Object.values(data.byId)
  const appDuration = application.duration || []

  const handleChange = e => {
    const newDuratoin = e.target.value
    let updatedDurations = [newDuratoin, ...appDuration]
    const isSelected = appDuration.includes(newDuratoin)
    if (isSelected)
      updatedDurations = updatedDurations.filter(
        selectedDuration => selectedDuration !== newDuratoin
      )
    updateApplication({ duration: updatedDurations })
  }

  return (
    <Content {...props}>
      <Heading>
        <Title>Duration</Title>
      </Heading>
      <Grid style={{ display: 'block' }} >
        {allDurations.map(duration => {
          const isChecked = appDuration.includes(duration.id)
          return (
            <Checkbox
              key={duration.name}
              duration={duration}
              handleChange={handleChange}
              checked={isChecked}
            />
          )
        })}
      </Grid>
    </Content>
  )
}

export default Durations
