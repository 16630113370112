import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getTrialData } from 'trials/store/selectors'
import { updateTrialData } from 'trials/store/actions'
import {
  Checkbox as MuiCheckbox,
  FormControlLabel as MuiFormControlLabel,
} from '@material-ui/core'

const Status = props => {
  const { hasConsented } = useSelector(getTrialData)

  const dispatchRedux = useDispatch()

  const handleChange = e =>
    dispatchRedux(updateTrialData({ hasConsented: e.target.checked }))

  const ConsentCheckbox = (
    <MuiCheckbox
      checked={hasConsented || false}
      onChange={handleChange}
      required
    />
  )

  //const error = !hasConsented || hasConsented === ''

  return (
    <MuiFormControlLabel
      label='I agree to receive quotes and related information from FindContent. I understand that I can withdraw my consent at any time'
      control={ConsentCheckbox}
    />
  )
}

export default Status
