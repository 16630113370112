import React, { useState } from 'react'
import { styled } from '@material-ui/core'
import { useFeaturesState, useEditApplication } from 'contentPublisher/hooks'
import { HIDDEN_FEATURES } from 'constants/hiddenFeatures'
import FeatureCheckbox from './FeatureCheckbox'
import ArrowRight from '@material-ui/icons/ArrowRight'
import ArrowDropDown from '@material-ui/icons/ArrowDropDown'

const CheckboxContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column'
})

const FeatureCheckboxTitle = styled('p')({
  margin: '5px 0',
  fontWeight: 'bold'
})

const FeatureSection = styled('div')({})

const ChildFeatureContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '1.7rem'
})

const CheckboxHeader = styled('div')({
  '&:hover': {
    cursor: 'pointer'
  },
  display: 'flex',
  position: 'relative',
  left: '14px'
})

const ExpandIcon = styled('div')({
  position: 'relative',
  right: '.5rem'
})

const CheckboxTree = ({ selectedFeatures, removeFeature }) => {
  const [application, updateApplication] = useEditApplication()
  const [openedFeatures, setOpenedFeatures] = useState([])
  const { data } = useFeaturesState()

  const checkIfSelected = ({ feature }) =>
    selectedFeatures.some(selectedFeature => selectedFeature.id === feature.id)

  const appFeatures = application.features || []

  const checkIfVisible = feature =>
    feature && !HIDDEN_FEATURES.includes(feature.name)

  const addFeature = id => {
    const set = new Set(appFeatures)
    set.add(id)
    updateApplication({ features: Array.from(set) })
  }

  const nameSorter = (a, b) => a.name.localeCompare(b.name)

  const allFeatures = Object.values(data.byId).sort(nameSorter)

  const handleFeatureExpand = ({ featureId }) => {
    const isExpanded = openedFeatures.includes(featureId)
    if (isExpanded) {
      const filteredOpenedFeatures = openedFeatures.filter(
        openedFeatureId => openedFeatureId !== featureId
      )
      setOpenedFeatures(filteredOpenedFeatures)
    } else {
      setOpenedFeatures([...openedFeatures, featureId])
    }
  }

  const handleCheck = ({ feature }) => {
    const isChecked = checkIfSelected({ feature })
    if (isChecked) {
      removeFeature(feature.id)
    } else {
      addFeature(feature.id)
    }
  }

  return (
    <CheckboxContainer>
      {allFeatures.map(feature => {
        const { children, name, id: featureId, parentId } = feature
        const isFeatureExpanded = openedFeatures.includes(featureId)
        if (children) {
          const sortedChildren = children.slice().sort(nameSorter)
          return (
            <FeatureSection key={featureId}>
              <CheckboxHeader
                onClick={() => handleFeatureExpand({ featureId })}
              >
                <ExpandIcon>
                  {isFeatureExpanded ? <ArrowDropDown /> : <ArrowRight />}
                </ExpandIcon>
                <FeatureCheckboxTitle>{name}</FeatureCheckboxTitle>
              </CheckboxHeader>
              {isFeatureExpanded && (
                <ChildFeatureContainer>
                  {sortedChildren.map(childFeature => {
                    const isChildFeatureVisible = checkIfVisible(childFeature)
                    const isSelected = checkIfSelected({
                      feature: childFeature
                    })
                    return (
                      isChildFeatureVisible && (
                        <FeatureCheckbox
                          key={childFeature.id}
                          feature={childFeature}
                          checked={isSelected}
                          handleChange={handleCheck}
                        />
                      )
                    )
                  })}
                </ChildFeatureContainer>
              )}
            </FeatureSection>
          )
        } else if (!parentId) {
          const isVisible = checkIfVisible(feature)
          const isSelected = checkIfSelected({ feature })
          return (
            isVisible && (
              <FeatureCheckbox
                key={featureId}
                feature={feature}
                checked={isSelected}
                handleChange={handleCheck}
                isStandAlone
              />
            )
          )
        } else {
          return <div key={featureId}></div>
        }
      })}
    </CheckboxContainer>
  )
}

export default CheckboxTree
