import produce from 'immer'
import { actionTypes } from './constants'

const initialState = {
  applications: {
    status: null,
    data: {
      byId: {},
      allIds: null,
      premiumIds: null,
    },
  },
  deliveryTypes: {
    status: null,
    data: {
      byId: {},
      allIds: [],
    },
  },
  courseStandards: {
    status: null,
    data: {
      byId: {},
      allIds: [],
    },
  },
  languages: {
    status: null,
    data: {
      byId: {},
      allIds: [],
    },
  },
  features: {
    status: null,
    data: {
      byId: {},
      allIds: [],
    },
  },
  contentTypes: {
    status: null,
    data: {
      byId: {},
      allIds: [],
    },
  },
  durations: {
    status: null,
    data: {
      byId: {},
      allIds: [],
    },
  },
  subjectTypes: {
    status: null,
    data: {
      byId: {},
      allIds: [],
    },
  },
  publishers: {
    status: null,
    data: {
      byId: {},
      allIds: [],
    },
  },
  compare: {
    systemIds: [],
  },
}

const initialApp = {
  type: 'LMS',
  isPublished: false,
}

const reducer = produce((draft, action) => {
  switch (action.type) {
    case actionTypes.FETCH_APPLICATIONS_REQUESTED:
      draft.applications.status = 'fetching'
      break

    case actionTypes.FETCH_APPLICATIONS_SUCCEEDED:
      draft.applications.status = 'succeeded'
      normalizeData(action.payload.results.data, draft.applications.data)
      break

    case actionTypes.FETCH_APPLICATIONS_FAILED:
      draft.applications.status = 'failed'
      break

    case actionTypes.FETCH_DURATIONS_REQUESTED:
      draft.durations.status = 'fetching'
      break

    case actionTypes.FETCH_DURATIONS_SUCCEEDED:
      draft.durations.status = 'succeeded'
      normalizeData(action.payload.results.data, draft.durations.data)
      break

    case actionTypes.FETCH_DURATIONS_FAILED:
      draft.durations.status = 'failed'
      break

    case actionTypes.FETCH_DELIVERY_TYPES_REQUESTED:
      draft.deliveryTypes.status = 'fetching'
      break

    case actionTypes.FETCH_DELIVERY_TYPES_SUCCEEDED:
      draft.deliveryTypes.status = 'succeeded'
      normalizeData(action.payload.results.data, draft.deliveryTypes.data)
      break

    case actionTypes.FETCH_DELIVERY_TYPES_FAILED:
      draft.deliveryTypes.status = 'failed'
      break

    case actionTypes.FETCH_COURSE_STANDARD_REQUESTED:
      draft.courseStandards.status = 'fetching'
      break

    case actionTypes.FETCH_COURSE_STANDARD_SUCCEEDED:
      draft.courseStandards.status = 'succeeded'
      normalizeData(action.payload.results.data, draft.courseStandards.data)
      break

    case actionTypes.FETCH_COURSE_STANDARD_FAILED:
      draft.courseStandards.status = 'failed'
      break

    case actionTypes.FETCH_FEATURES_REQUESTED:
      draft.features.status = 'fetching'
      break

    case actionTypes.FETCH_FEATURES_SUCCEEDED:
      draft.features.status = 'succeeded'
      normalizeData(action.payload.results, draft.features.data)
      break

    case actionTypes.FETCH_FEATURES_FAILED:
      draft.features.status = 'failed'
      break

    case actionTypes.FETCH_LANGUAGES_REQUESTED:
      draft.languages.status = 'fetching'
      break

    case actionTypes.FETCH_LANGUAGES_SUCCEEDED:
      draft.languages.status = 'succeeded'
      normalizeData(action.payload.results.data, draft.languages.data)
      break

    case actionTypes.FETCH_LANGUAGES_FAILED:
      draft.languages.status = 'failed'
      break

    case actionTypes.FETCH_CONTENT_TYPES_REQUESTED:
      draft.contentTypes.status = 'fetching'
      break

    case actionTypes.FETCH_CONTENT_TYPES_SUCCEEDED:
      draft.contentTypes.status = 'succeeded'
      normalizeData(action.payload.results.data, draft.contentTypes.data)
      break

    case actionTypes.FETCH_CONTENT_TYPES_FAILED:
      draft.contentTypes.status = 'failed'
      break

    case actionTypes.FETCH_SUBJECT_TYPES_REQUESTED:
      draft.subjectTypes.status = 'fetching'
      break

    case actionTypes.FETCH_SUBJECT_TYPES_SUCCEEDED:
      draft.subjectTypes.status = 'succeeded'
      normalizeData(action.payload.results.data, draft.subjectTypes.data)
      break

    case actionTypes.FETCH_SUBJECT_TYPES_FAILED:
      draft.subjectTypes.status = 'failed'
      break

    case actionTypes.FETCH_PUBLISHERS_REQUESTED:
      draft.publishers.status = 'fetching'
      break

    case actionTypes.FETCH_PUBLISHERS_SUCCEEDED:
      draft.publishers.status = 'succeeded'
      normalizeData(action.payload.results.data, draft.publishers.data)
      break

    case actionTypes.FETCH_PUBLISHERS_FAILED:
      draft.publishers.status = 'failed'
      break

    case actionTypes.EDIT_APPLICATION_BEGIN:
      const { id } = action.payload
      const data = id ? draft.applications.data.byId[id] : initialApp
      draft.editing = { data }
      break

    case actionTypes.EDIT_APPLICATION_CHANGE:
      Object.assign(draft.editing.data, action.payload)
      break

    case actionTypes.EDIT_APPLICATION_ADD_CASE_STUDY:
      draft.editing.data.caseStudies.push(action.payload)
      
      break

    case actionTypes.EDIT_APPLICATION_ADD_PLAYLIST_ITEM:
      draft.editing.data.playlistItems.push({
        ...action.payload
      })
      break
    
    case actionTypes.UPLOADING_FILE: {
      if (!draft.editing.data.uploadingFiles) {
        draft.editing.data.uploadingFiles = []
      }

      const existingIndex = draft.editing.data.uploadingFiles.findIndex(i => i.uploadUrl === action.payload.uploadUrl)
      if (existingIndex >= 0) {
        if (action.payload.done === true) {
          draft.editing.data.uploadingFiles.splice(existingIndex, 1)
        } else {
          draft.editing.data.uploadingFiles[existingIndex] = action.payload
        }
      } else {
        draft.editing.data.uploadingFiles.push(action.payload)
      }
    }
    break
    
    
    case actionTypes.SAVE_PLAYLIST_ITEM_ORDER_SUCCEEDED:
      action.payload.forEach((newItem,index) => {
        const itemIndex = draft.editing.data.playlistItems.findIndex(i => i.id === newItem.id)
        draft.editing.data.playlistItems[itemIndex].order = index
      })
      break

    case actionTypes.EDIT_PLAYLIST_ITEM_SUCCEEDED: {
      const itemIndex = draft.editing.data.playlistItems.findIndex(i => i.id === action.payload.id)
      draft.editing.data.playlistItems[itemIndex] = {
        ...draft.editing.data.playlistItems[itemIndex],
        ...action.payload
      }
    }
      break

    case actionTypes.FETCH_PLAYLIST_ITEM_VIEWS_SUCCEEDED:
      draft.playlistItemViews = action.payload.results.data
      break

    case actionTypes.DELETE_PLAYLIST_ITEM_SUCCEEDED: {
      const itemIndex = draft.editing.data.playlistItems.findIndex(i => i.id === action.payload.id)
      draft.editing.data.playlistItems.splice(itemIndex, 1)
      //Remove from the original as well
      const original = draft.applications.data.byId[draft.editing.data.id]
      const orginalIndex = original.playlistItems.findIndex(i => i.id === action.payload.id)
      original.playlistItems.splice(orginalIndex, 1)
    }
      break

    case actionTypes.EDIT_PLAYLIST_ITEM_THUMBNAIL_REQUESTED: {
      draft.editing.data.playlistItemThumbnail = action.payload.thumbnail
      break
    }

    case actionTypes.EDIT_APPLICATION_UPDATE_CASE_STUDY: {
      const { id, ...updates } = action.payload
      const index = draft.editing.data.caseStudies.findIndex(cs => cs.id === id)
      Object.assign(draft.editing.data.caseStudies[index], { ...updates })
      break
    }

    case actionTypes.SAVE_APPLICATION_SUCCEEDED: {
      const { _id: id } = action.payload
      draft.applications.data.byId[id] = { id, ...action.payload }

      const set = new Set(draft.applications.data.allIds)
      set.add(id)
      draft.applications.data.allIds = Array.from(set)

      break
    }

    case actionTypes.ADD_SYSTEMS_TO_COMPARE_LIST: {
      const ids = [action.payload.ids].flat()
      draft.compare.systemIds = Array.from(
        new Set([...draft.compare.systemIds, ...ids])
      )
      break
    }

    case actionTypes.REMOVE_SYSTEMS_FROM_COMPARE_LIST: {
      const ids = [action.payload.ids].flat()
      draft.compare.systemIds = draft.compare.systemIds.filter(
        id => !ids.includes(id)
      )
      break
    }

    // no default
  }
}, initialState)

const normalizeData = (source, target) => {
  console.log()
  const ids = new Set()

  const mostRecentSorter = (a, b) =>
    new Date(a.updatedAt) - new Date(b.updatedAt)

  const sortedApplications = source.sort(mostRecentSorter)

  sortedApplications.forEach(result => {
    ids.add(result.id)
    target.byId[result.id] = result

    if (result.children) {
      result.children.forEach(child => {
        target.byId[child.id] = child
      })
    }
  })

  const allIdsArray = Array.from(ids)

  const nameSorter = (a, b) =>
    target.byId[a].name.localeCompare(target.byId[b].name)

  const sortedApps = allIdsArray.reduce(
    (acc, id) => {
      const isPremium = target.byId[id].isPremium
      if (isPremium) {
        acc.premium.push(id)
      } else {
        acc.nonPremium.push(id)
      }
      return acc
    },
    {
      premium: [],
      nonPremium: [],
    }
  )

  const sortedPremiumAppsbyName = sortedApps.premium.slice().sort(nameSorter)
  const sortedAppsbyName = sortedApps.nonPremium.slice().sort(nameSorter)

  target.allIds = [...sortedPremiumAppsbyName, ...sortedAppsbyName]
  target.premiumIds = sortedPremiumAppsbyName
}

export default reducer
