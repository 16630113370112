import React from 'react'
import { useDispatch } from 'react-redux'
import { updateCurrentUser } from 'users/store/actions'
import produce from 'immer'
import {
  styled,
  TextField as MuiTextField,
  Button as MuiButton,
} from '@material-ui/core'
import { updateTrialData } from 'trials/store/actions'
import CountrySelect from 'ui/components/CountrySelect'

const Form = styled('div')({})
const SaveProfileButton = styled(MuiButton)({
  margin: '.25rem',
})

const EditContactForm = props => {
  const { closeEditingMode, currentUser, updateUser } = props
  const dispatchRedux = useDispatch()

  const {
    firstName,
    lastName,
    email,
    phone,
    companyName,
    jobTitle,
    city,
    state,
    country,
  } = currentUser

  const handleContactInfoSave = () => {
    const updatedUserPayload = { ...currentUser }
    delete updatedUserPayload['trials']
    const trialData = {
      contact: {
        fullName: `${firstName} ${lastName}`,
        email,
        phone,
        companyName,
        jobTitle,
        city,
        state,
        country,
      },
    }
    dispatchRedux(
      updateCurrentUser(updatedUserPayload, 'Profile Contact Information Saved')
    )
    dispatchRedux(updateTrialData(trialData))
    closeEditingMode()
  }

  const handleChange = changes => {
    const updatedUser = produce(currentUser, draft =>
      Object.assign(draft, changes)
    )
    updateUser(updatedUser)
  }

  return (
    <Form>
      <MuiTextField
        value={firstName || ''}
        onChange={e => handleChange({ firstName: e.target.value })}
        placeholder='Enter your first name'
        fullWidth
        margin='dense'
      />
      <MuiTextField
        value={lastName || ''}
        onChange={e => handleChange({ lastName: e.target.value })}
        placeholder='Enter your last name'
        fullWidth
        margin='dense'
      />
      <MuiTextField
        value={jobTitle || ''}
        onChange={e => handleChange({ jobTitle: e.target.value })}
        placeholder='Enter your job title'
        fullWidth
        margin='dense'
      />
      <MuiTextField
        value={companyName || ''}
        onChange={e => handleChange({ companyName: e.target.value })}
        placeholder='Enter the name of your company'
        fullWidth
        margin='dense'
      />
      <MuiTextField
        value={email || ''}
        onChange={e => handleChange({ email: e.target.value })}
        placeholder='Enter your email address'
        fullWidth
        margin='dense'
      />
      <MuiTextField
        value={phone || ''}
        onChange={e => handleChange({ phone: e.target.value })}
        placeholder='Enter your phone number'
        fullWidth
        margin='dense'
      />
      <MuiTextField
        value={city || ''}
        onChange={e => handleChange({ city: e.target.value })}
        placeholder='Enter your city'
        fullWidth
        margin='dense'
      />
      <MuiTextField
        value={state || ''}
        onChange={e => handleChange({ state: e.target.value })}
        placeholder='Enter your state/province'
        fullWidth
        margin='dense'
      />
      <CountrySelect
        value={country || ''}
        onChange={e => handleChange({ country: e.target.value })}
        placeholder='Enter your country'
        fullWidth
        variant='standard'
      />
      <SaveProfileButton
        variant='contained'
        color='primary'
        size='small'
        onClick={handleContactInfoSave}
      >
        Save
      </SaveProfileButton>
    </Form>
  )
}

export default EditContactForm
