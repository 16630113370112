import React from 'react'
import {styled} from '@material-ui/core/styles'
import MuiGrid from '@material-ui/core/Grid'

const Content = styled(MuiGrid)(({theme}) => ({
  padding: 20,

  [theme.breakpoints.up('sm')]: {
    padding: '20px 40px',
  },
}))

const Body = ({children, ...props}) => {
  return (
    <Content container justifyContent="center" {...props}>

      {children}
    </Content>
  )
}

export default Body
