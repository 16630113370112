import React from 'react'
import { styled } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { getEditApplicationState } from 'contentPublisher/store/selectors'
// import { getMode } from 'app/store/selectors'
import BackButton from 'ui/components/BackButton'
import DeleteButton from './DeleteButton'
import SaveButton from './SaveButton'
import BasicInfo from './BasicInfo'
import ContactInfo from './ContactInfo'
import Description from './Description'
import SubjectType from './SubjectType'
import ContentType from './ContentType'
import Features from './Features'
import SystemData from './SystemData'
import Duration from './Duration'
import DeliveryType from './DeliveryTypes'
import Languages from './Languages'
// import Publishers from './Publishers'
// import CourseLibrary from './CourseLibrary'
import CourseStandard from './CourseStandards'
// import InstilledPlayer from './InstilledPlayer'
import Playlist from "../../Detail/Playlist"

const Content = styled('form')({})

const Header = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: 20,
})

const Title = styled('div')({
  fontSize: 24,
  fontWeight: 500,
})

const Actions = styled('div')({
  display: 'grid',
  gridAutoFlow: 'column',
  gridColumnGap: 10,
})

const Form = () => {
  const { data } = useSelector(getEditApplicationState)
  // const mode = useSelector(getMode)
  // const isVendor = mode === 'vendor'
  if (!data) return null

  const title = data.id ? 'Edit Content Publisher' : 'New Content Publisher'

  let playlistItems = data.playlistItems ? data.playlistItems : []
  let sorted = playlistItems.slice(0).sort((a,b) => {
    if (a.order < b.order){ return -1 }
    if (a.order > b.order) { return 1 }
    else { return 0 }
  })

  return (
    <Content>
      <Header>
        <Title>{title}</Title>
        <Actions>
          <DeleteButton />
          <BackButton disabled={data.uploadingFiles?.length > 0} />
          <SaveButton disabled={data.uploadingFiles?.length > 0}  />
        </Actions>
      </Header>
      <SystemData contentPublisher={data}/>
      <BasicInfo />
      <Description />
      <Playlist isEdit items={sorted} isPremium={data.isPremium}/>
      <ContactInfo />
      {/*<InstilledPlayer id={data.id} mode={mode} />*/}
      {/* <CourseLibrary /> */}
      <ContentType />
      <Duration />
      <SubjectType />
      <DeliveryType />
      <CourseStandard />
      <Languages />
      {/* <Publishers /> */}
      <Features />
    </Content>
  )
}

export default Form
