import React, {useState} from "react"
import {Chip as MuiChip, styled} from "@material-ui/core"
import PublishIcon from '@material-ui/icons/Publish'
import MuiButton from "@material-ui/core/Button"
import {editPlaylistItem, savePlaylistItem, deletePlaylistItem} from '../../../store/actions'
import {useDispatch} from "react-redux"
import './addEditPlaylist.css'
import MuiTextField from "@material-ui/core/TextField"
import MuiInputLabel from "@material-ui/core/InputLabel"
import ThumbnailSelection from "./ThumbnailSelection"
import Button from "@material-ui/core/Button"
import MuiDeleteIcon from "@material-ui/icons/Delete"
import ConfirmModal from "../../../../ui/components/Modals/ConfirmModal"

const AddEditPlaylist = ({editItem, isEdit, inPlayerViewItem, setSelectedItem, onClearEditItem}) => {
  const [title,setTitle] = useState('')
  const [description,setDescription] = useState('')
  const [existingItem,setExistingItem] = useState()

  const [existingThumbnail, setExistingThumbnail] = useState()

  const [isAdding,setIsAdding] = useState()
  const [isSaving,setIsSaving] = useState(false)

  const [selectedVideo,setSelectedVideo] = useState()
  const [selectedPdf,setSelectedPdf] = useState()
  const [selectedUrl,setSelectedUrl] = useState('')
  const [selectingThumbnail,setSelectingThumbnail] = useState(false)
  const [isDeleting,setIsDeleting] = useState(false)

  const [selectedThubmnail,setSelectedThumbnail] = useState()

  const [urlError,setUrlError] = useState(false)

  const dispatchRedux = useDispatch()

  const ChipButton = styled(MuiChip)({
    marginRight: '.5rem',
    marginLeft: '.5rem',
    cursor: 'pointer',
  })


  const getItemType = item => {
    if (item.embedUrl?.length > 0)
      return 'link'
    else if (item.file?.mimeType.includes('pdf'))
      return 'pdf'

    return 'video'
  }

  React.useEffect(() => {
    if (editItem){
      setExistingItem(editItem)
      setTitle(editItem.title)
      setDescription(editItem.description)
      setExistingThumbnail(editItem.thumbnail?.id === undefined ? undefined : editItem.thumbnail)

      const itemType = getItemType(editItem)
      setIsAdding(itemType)
      if (itemType === 'link') {
        setSelectedUrl(editItem.embedUrl)
      }else if (itemType === 'pdf'){
        setSelectedPdf(editItem.file)
      }else{
        setSelectedVideo(editItem.file)
      }

    }
  },[editItem])

  const resetState = () => {
    setIsAdding()
    setIsSaving(false)
    setTitle('')
    setDescription('')
    setSelectedUrl('')
    setSelectedPdf(undefined)
    setSelectedVideo(undefined)
    setSelectingThumbnail(false)
    setSelectedThumbnail()
    setExistingThumbnail()
    setExistingItem()
    onClearEditItem()
  }

  const cancel = () => {
    resetState()
  }

  const onDelete = () => {
    dispatchRedux(deletePlaylistItem(existingItem.id))
    resetState()

    // Clear player if item in view is deleted
    if(inPlayerViewItem === editItem) {
      setSelectedItem('')
    }
  }

  const handleVideoFileInput = async (event) => {
    const {files} = event.target
    const file = files[0]
    setSelectedVideo(file)
  }

  const onNext = event => {
    // Show the thumbnail selection
    setSelectingThumbnail(true)
  }

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n)
    while(n--){
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new File([u8arr], filename, {type:mime})
  }

  const onSave = event => {
    try {
      setIsSaving(true)

      let thumbnailFile = selectedThubmnail
      if (typeof selectedThubmnail === "string") {
        thumbnailFile = dataURLtoFile(selectedThubmnail, "SelectedThumbnail")
      }

      if (existingItem) {
        dispatchRedux(editPlaylistItem({
          _id: existingItem.id,
          title,
          description,
          embedUrl: selectedUrl,
          /*
          If thumbnailFile has a value, use it. Otherwise check if existingItem.thumbnail.id is undefined. If it is, set thumbnail to undefined.
          Otherwise, use existingItem.thumbnail as it should have a valid value.
          */
          thumbnail: thumbnailFile ? thumbnailFile : existingItem.thumbnail?.id === undefined ? undefined : existingItem.thumbnail,
          existingThumbnailId: existingItem.thumbnail?.id,
          file: isAdding === 'pdf'? selectedPdf : selectedVideo,
          existingFileId: existingItem.file?.id,
        }))
      }else {

        if (isAdding === 'video' && selectedVideo) {
          dispatchRedux(savePlaylistItem({
            type: 'video',
            category: 'application/caseStudy',
            file: selectedVideo,
            thumbnail: thumbnailFile,
            title,
            description
          }))
        } else if (isAdding === 'pdf' && selectedPdf) {
          dispatchRedux(savePlaylistItem({
            type: 'whitepaper',
            category: 'application/caseStudy',
            file: selectedPdf,
            thumbnail: thumbnailFile,
            title,
            description
          }))
        } else if (isAdding === 'link' && selectedUrl) {
          dispatchRedux(savePlaylistItem({
            title,
            description,
            thumbnail: thumbnailFile,
            embedUrl: selectedUrl
          }))
        }
      }

    }finally {
      resetState()
    }
  }

  const handlePdfFileInput = event => {
    const {files} = event.target
    const file = files[0]
    setSelectedPdf(file)
  }

  const onThumbnailChanged = thumbnail => {
    setSelectedThumbnail(thumbnail)
  }

  const ActionButton = styled(MuiButton)(({theme}) => ({
    marginRight: '2rem'
  }))

  const FileInput = styled('input')({
    display: 'none',
  })



  const canSave = () => {
    if (urlError)
      return false
    
    switch (isAdding){
      case 'video':
        return selectedVideo
      case 'pdf':
        return selectedPdf
      case 'link':
        return selectedUrl
      default:
        return false
    }
  }
  return (<>
    <div className='add-edit-playlist'>
      {isEdit && !editItem &&
        <div className='add-edit-buttons'>
          <label>Add Resource:</label>
          <label onClick={() => { 
            setIsAdding('video')
           }}>
            <ChipButton
              label="Video"
              size="small"
              color="primary"
              className={isAdding === 'video' ? 'active' : ''}
            />
          </label>
          <label onClick={() => { 
            setIsAdding('link')
           }}>
            <ChipButton
              label="External Link"
              size="small"
              color="primary"
              className={isAdding === 'link' ? 'active' : ''}
            />
          </label>
          <label onClick={() => { 
            setIsAdding('pdf')
           }}>
            <ChipButton
              label="PDF"
              size="small"
              color="primary"
              className={isAdding === 'pdf' ? 'active' : ''}
            />
          </label>
        </div>
      }
      {isEdit && editItem &&
          <div className='add-edit-buttons'>
            <label>Edit {getItemType(editItem)}</label>
          </div>
      }

      {isAdding && !selectingThumbnail &&
          <>
            <div className='add-edit-content-details'>
              <MuiTextField label='Title'
                style={{margin: '1rem 0'}}
                size='small'
                type="text"
                id="title"
                variant='outlined'
                value={title}
                onChange={e => setTitle(e.target.value)}/>
              <MuiTextField multiline label='Description'
                style={{margin: '1rem 0'}}
                maxRows={12}
                fullWidth
                variant='outlined'
                id="description"
                value={description}
                onChange={e => setDescription(e.target.value)}/>
            </div>
            <div className='add-edit-content'>
              {isAdding === 'video' &&
                  <>
                    <MuiInputLabel htmlFor='videos-file-input'>
                      <MuiButton component='span'
                        startIcon={<PublishIcon />}
                        size="medium"
                        color="primary"
                        variant='contained'
                      >Select video to upload</MuiButton>
                    </MuiInputLabel>
                    <FileInput
                      id="videos-file-input"
                      accept="video/mp4"
                      type="file"
                      onChange={handleVideoFileInput}
                    />
                    {selectedVideo &&
                        <h5 className="add-edit-selected-filename">Selected video: {selectedVideo.name} </h5>
                    }
                  </>
              }
              {isAdding === 'pdf' &&
                  <>
                    <label htmlFor='pdf-file-input'>
                      <MuiButton
                        component='span'
                        size="medium"
                        startIcon={<PublishIcon />}
                        color="primary"
                        variant='contained'>
                        Select PDF to upload
                      </MuiButton>

                    </label>
                    <FileInput
                      id="pdf-file-input"
                      accept="application/pdf"
                      type="file"
                      onChange={handlePdfFileInput}
                    />
                    {selectedPdf &&
                        <h5 className="add-edit-selected-filename">Selected PDF: {selectedPdf.name} </h5>
                    }
                  </>
              }
              {isAdding === 'link' &&
                  <>
                    <MuiTextField
                      helperText="URL must begin with https://"
                      value={selectedUrl}
                      required
                      error= {urlError}
                      label='External URL'
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      id="url-input"
                      type="url"
                      onChange={(e) => {
                        const pattern = /https:\/\//
                        const valid = e.target.value.match(pattern)
                        if (!valid) {
                          setUrlError(true)
                        }else{
                          setUrlError(false)
                        }
                        setSelectedUrl(e.target.value)
                        }
                      }
                    />
                    <label style={{display: 'block', marginTop: '10px'}}>Note: The External URL source must not prevent app.findcontent.io from loading it within an iframe. If you experience a loading error, check the source's Content-Security-Policy (CSP) and X-Frame-Options. The following CSP header will allow app.findcontent.io to load the source: <pre>Content-Security-Policy: frame-src https://app.findcontent.io</pre></label>
                  </>
              }
            </div>
          </>
      }
      {selectingThumbnail &&
        <ThumbnailSelection fileType={isAdding} video={selectedVideo} selectedThumbnailChanged={onThumbnailChanged} existingThumbnail={existingThumbnail} existingItem={existingItem} />
      }
      <div className='add-edit-content-buttons'>
        { isAdding && !selectingThumbnail &&
            <ActionButton disabled={isSaving || !canSave()} onClick={onNext} color='primary' variant="contained">Next</ActionButton>
        }
        { isAdding && selectingThumbnail &&
          <ActionButton disabled={isSaving || !canSave()} onClick={onSave} color='primary' variant="contained">{existingItem ? 'Update' : 'Add'}</ActionButton>
        }
        { isAdding &&
            <ActionButton onClick={()=> cancel()}>Cancel</ActionButton>
        }
        { existingItem && isAdding &&
            <>
              <Button style={{marginLeft: '2rem'}} endIcon={<MuiDeleteIcon/> } size="small" onClick={() => setIsDeleting(true)}>delete resource</Button>
              <ConfirmModal
                confirmText="Are you sure you want to delete this playlist item?"
                open={isDeleting}
                handleConfirm={() =>  onDelete()}
                handleClose={() => setIsDeleting(false)}
              />
            </>
        }
      </div>
    </div>
  </>)
}
export default AddEditPlaylist
