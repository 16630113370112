import React from 'react'
import { styled } from '@material-ui/core/styles'
import Header from './Header'
import Description from './Description'
import Features from './Features'

// import Videos from './Videos'
// import WhitePapers from './WhitePapers'
import CourseStandards from './CourseStandards'
import ContentTypes from './ContentTypes'
import Duration from './Duration'
import Category from './Category'
import Delivery from './Delivery'
import Languages from './Languages'
//import InstilledPlayer from './InstilledPlayer'
import Playlist from './Playlist'
//import Industries from './Industries'
//import Ranking from './Ranking'
//import BusinessSegment from './BusinessSegment'

const Content = styled('div')(({ theme }) => ({
  position: 'relative',
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
}))

const Body = styled('div')({})
const Detail = ({ contentPublisher, ...props }) => {
  const { description, features, courseStandards, content, duration, subject, delivery, languages } = contentPublisher
  
  let { playlistItems } = contentPublisher
  let sorted = playlistItems.slice(0).sort((a,b) => {
    if (a.order < b.order){ return -1 }
    if (a.order > b.order) { return 1 }
    else { return 0 }
  })

  return (
    <Content {...props}>
      <Header contentPublisher={contentPublisher} />
      <Body>
        <Description description={description} />
        {contentPublisher.isPremium &&  
        <Playlist isEdit={false} items={sorted} applicationId={contentPublisher.id} isPremium={contentPublisher.isPremium} trial={contentPublisher.trial}/>
        }
        <CourseStandards courseStandards={courseStandards} />
        <ContentTypes content={content} />
        <Duration duration={duration} />
        <Category category={subject} />
        <Delivery delivery={delivery} />
        <Languages languages={languages} />
        <Features features={features} />
        {/* <Ranking rankings={craigsRatings} />
        <Videos videos={videos} />
        <WhitePapers whitePapers={whitePapers} />
        <CourseStandards courseStandards={courseStandards} />
        <Industries industries={industries} />
        <BusinessSegment businessSegments={businessSegments} /> */}
      </Body>
    </Content>
  )
}

export default Detail
