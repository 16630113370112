import React, {useEffect} from "react"
import {useSelector,useDispatch} from "react-redux"
import {trackPlaylistItemView} from "../../../store/actions"
import {getCurrentUser} from "../../../../users/store/selectors";
// import { styled } from '@material-ui/core/styles'
//import RfpButton from 'rfps/components/Button'
import { openTrialDialog } from 'trials/store/actions'
import {styled, Button as MuiButton} from '@material-ui/core'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

const Button = styled(MuiButton)(({theme}) => ({
  backgroundColor: 'white',
  height: 'fit-content',
  padding: '10px 15px',
  '&:disabled': {
    color: theme.palette.success.main
  }
}))

const PlaylistItemViewer = ({item, applicationId, trackViews = false, isPremium = false, trial = false}) => {

  let watchTime = 0
  let trackingId
  const dispatchRedux = useDispatch()
/*
  const StyledRfpButton = styled(RfpButton)(({ theme }) => ({
    backgroundColor: '#ffffff'
  }))
  
  let rfpButton = <StyledRfpButton systemId={applicationId} />  
*/
  const handleRequestPricing = event => {
    event.preventDefault()
    event.stopPropagation()    
    dispatchRedux(openTrialDialog(applicationId))
  }

  let buttonText = 'Request Trial'
  let disabled, hasRequestedQuote

  const visibilitychange = () =>  {
    if (document.visibilityState === "visible") {
      if (watchTime > 0 ) {    
        startTrackingDuration()
      }
    } else {
      if (trackingId){
        stopTrackingDuration()
      }
    }
  };

  useEffect(() => {
    document.addEventListener("visibilitychange", visibilitychange)
    
    //This will give us a call to track view on the unload of the the item which helps to track the last item viewed when clicking away
    return () => {
      // Specify how to clean up after this effect:
      document.removeEventListener("visibilitychange", visibilitychange)

      stopTrackingDuration()
      //console.log('stopTrackingDuration unmount')
      trackView(item)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item])


  const currentUser = useSelector(getCurrentUser)

  if (
    currentUser &&
    currentUser.trials &&
    currentUser.trials.some(r => r.application === applicationId)
  ) {
    buttonText = 'Quote Requested'
    disabled = true
    hasRequestedQuote = true
  }

  const isVideo = mimeType => {
    return  mimeType && mimeType.includes('video')
  }
  const isDocument = mimeType => {
    return mimeType && mimeType.includes('pdf')
  }


  const trackView = (item) => {
    if (item && trackViews && currentUser) {
      dispatchRedux(trackPlaylistItemView({
        user: currentUser,
        item,
        duration: watchTime > 0 ? watchTime : undefined,
        applicationId: applicationId
      }))
    }
  }

  const startTrackingDuration = (e) => {
    if (trackingId){
      stopTrackingDuration()
    }
    
    trackingId = setInterval(() => {
      watchTime += 1
      //console.log(`Watch time is ${watchTime}`)
    },1000)

  }

  const stopTrackingDuration = () => {
    clearInterval(trackingId)
  }

  return (
    <div className='player-outer'>
      <div className='player-inner'>
      {!item &&
                <div className='empty-playlist-item'>Click on the content to the right to view <ArrowRightAltIcon/></div>
      }
      {item && isVideo(item.file?.mimeType) &&
                <video className='video-playlist-item' controls autoPlay src={`${item.file.uri}`} onPlay={startTrackingDuration} onPause={stopTrackingDuration}   />
      }
      {item && isDocument(item.file?.mimeType) &&
                <embed className='pdf-playlist-item' src={item.file.uri} onLoad={startTrackingDuration} />
      }
      {item && item.embedUrl &&
                <iframe className='iframe-playlist-item' title={item.title} src={item.embedUrl} onLoad={startTrackingDuration} />
      }
      </div>

      
        <div className="playlist-item-metadata">
        {item && 
        <>
          <div className="playlist-item-metadata-inner">
            <div className="playlist-item-title">{item.title}</div>
            <div className="playlist-item-description">{item.description}</div>
          </div>
          <div className="playlist-actions">
            {isPremium && trial &&
            <Button
            onClick={handleRequestPricing}
            color='primary'
            variant='outlined'
            disabled={disabled}
            style={{
              color: hasRequestedQuote
                  ? 'rgb(67, 160, 71)'
                  : '',
              border: `1px solid ${ hasRequestedQuote ? '#D3D3D3' : ''
                }`,
            }}
          >
            {buttonText}
          </Button>}
          </div>
          </>
        }
        </div>
      
    </div>
    
  )
}

export default PlaylistItemViewer