import mirrorCreator from 'mirror-creator'

export const actionTypes = mirrorCreator(
  [
    'FETCH_APPLICATIONS_REQUESTED',
    'FETCH_APPLICATIONS_SUCCEEDED',
    'FETCH_APPLICATIONS_FAILED',

    'FETCH_CONTENT_TYPES_REQUESTED',
    'FETCH_CONTENT_TYPES_SUCCEEDED',
    'FETCH_CONTENT_TYPES_FAILED',

    'FETCH_DELIVERY_TYPES_REQUESTED',
    'FETCH_DELIVERY_TYPES_SUCCEEDED',
    'FETCH_DELIVERY_TYPES_FAILED',

    'FETCH_COURSE_STANDARD_REQUESTED',
    'FETCH_COURSE_STANDARD_SUCCEEDED',
    'FETCH_COURSE_STANDARD_FAILED',

    'FETCH_LANGUAGES_REQUESTED',
    'FETCH_LANGUAGES_SUCCEEDED',
    'FETCH_LANGUAGES_FAILED',

    'FETCH_PUBLISHERS_REQUESTED',
    'FETCH_PUBLISHERS_SUCCEEDED',
    'FETCH_PUBLISHERS_FAILED',

    'FETCH_DURATIONS_REQUESTED',
    'FETCH_DURATIONS_SUCCEEDED',
    'FETCH_DURATIONS_FAILED',

    'FETCH_FEATURES_REQUESTED',
    'FETCH_FEATURES_SUCCEEDED',
    'FETCH_FEATURES_FAILED',

    'FETCH_SUBJECT_TYPES_REQUESTED',
    'FETCH_SUBJECT_TYPES_SUCCEEDED',
    'FETCH_SUBJECT_TYPES_FAILED',

    'EDIT_APPLICATION_BEGIN',
    'EDIT_APPLICATION_CHANGE',
    'EDIT_APPLICATION_ADD_CASE_STUDY',
    'EDIT_APPLICATION_UPDATE_CASE_STUDY',
    'EDIT_APPLICATION_ADD_PLAYLIST_ITEM',

    'SAVE_APPLICATION_REQUESTED',
    'SAVE_APPLICATION_SUCCEEDED',
    'SAVE_APPLICATION_FAILED',

    'SAVE_PLAYLIST_ITEM_ORDER_REQUESTED',
    'SAVE_PLAYLIST_ITEM_ORDER_SUCCEEDED',
    'SAVE_PLAYLIST_ITEM_ORDER_FAILED',

    'SAVE_PLAYLIST_ITEM_REQUESTED',
    'SAVE_PLAYLIST_ITEM_SUCCEEDED',
    'SAVE_PLAYLIST_ITEM_FAILED',

    'FETCH_PLAYLIST_ITEM_VIEWS_REQUESTED',
    'FETCH_PLAYLIST_ITEM_VIEWS_SUCCEEDED',
    'FETCH_PLAYLIST_ITEM_VIEWS_FAILED',

    'TRACK_PLAYLIST_ITEM_VIEW_REQUESTED',
    'TRACK_PLAYLIST_ITEM_VIEW_SUCCEEDED',
    'TRACK_PLAYLIST_ITEM_VIEW_FAILED',

    'EDIT_PLAYLIST_ITEM_REQUESTED',
    'EDIT_PLAYLIST_ITEM_SUCCEEDED',
    'EDIT_PLAYLIST_ITEM_FAILED',

    'DELETE_PLAYLIST_ITEM_REQUESTED',
    'DELETE_PLAYLIST_ITEM_SUCCEEDED',
    'DELETE_PLAYLIST_ITEM_FAILED',

    'EDIT_PLAYLIST_ITEM_THUMBNAIL_REQUESTED',
    'EDIT_PLAYLIST_ITEM_THUMBNAIL_SUCCEEDED',
    'EDIT_PLAYLIST_ITEM_THUMBNAIL_FAILED',

    'UPLOAD_FILE_REQUESTED',
    'UPLOADING_FILE',

    'DELETE_APPLICATION_REQUESTED',

    'VIEW_CONTENT_PROVIDER_DETAILS',
    'VIEW_APPLICATION_CONTACT_INFO',
    'WEBSITE_LINK_VISITED',

    'ADD_SYSTEMS_TO_COMPARE_LIST',
    'REMOVE_SYSTEMS_FROM_COMPARE_LIST',
    'COMPARE_SYSTEMS',
  ],
  { prefix: 'contentPublisher/' }
)
