import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { styled } from '@material-ui/core/styles'
import MuiPaper from '@material-ui/core/Paper'
import { getApplicationsState } from 'contentPublisher/store/selectors'
import { useCurrentSearchResults } from 'searches/hooks'
import Card from 'contentPublisher/components/Card'
import TrialDialog from 'trials/components/Dialog'
import { useMode } from 'app/hooks'
import CompareCard from 'contentPublisher/components/Compare/Card'
import CreateAppButton from './CreateAppButton'
import Loader from '../Loader'
import SortBy from './SortBy'
import LazyLoad from 'react-lazyload'

const Content = styled('ul')({
  flex: '0 1 auto',
  margin: 0,
  padding: 0,
  listStyleType: 'none'
})

const ListHeader = styled('li')(({ theme }) => ({}))

const Split = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(1),
}))

const Count = styled('p')(({ theme }) => ({
  margin: 0,
  color: theme.palette.primary.contrastText,
  fontSize: 20,
}))

const ListItem = styled('li')({
  marginBottom: 20,
})

const EmptyList = styled(MuiPaper)({
  padding: 50,
  fontSize: 18,
  textAlign: 'center',
  width: '100%',
})

const ContentPublisherCardComponent = ({ contentPublisher }) => (
  <ListItem>
    <Card contentPublisher={contentPublisher} />
  </ListItem>
)

const List = ({ hasFetchedApplications }) => {
  const {
    data: { allIds, byId, premiumIds },
  } = useSelector(getApplicationsState)

  const filteredIds = useCurrentSearchResults()

  const [sortType, setSortType] = useState('A-Z')

  const [mode] = useMode()

  const updatedAtNewSorter = (a, b) =>
    new Date(byId[b].updatedAt) - new Date(byId[a].updatedAt)
  const updatedAtOldSorter = (a, b) =>
    new Date(byId[a].updatedAt) - new Date(byId[b].updatedAt)

  const ascendingAlphaSorter = (a, b) =>
    byId[a].name.localeCompare(byId[b].name)
  const descendingAlphaSorter = (a, b) =>
    byId[b].name.localeCompare(byId[a].name)

  const averageRatingSorter = (a, b) => {
    const aContentPublisher = byId[a]
    const bContentPublisher = byId[b]
    const { craigsRatings: aContentPublisherCraigsRatings } = aContentPublisher
    const { craigsRatings: bContentPublisherCraigsRatings } = bContentPublisher
    if (aContentPublisherCraigsRatings && bContentPublisherCraigsRatings) {
      return (
        bContentPublisherCraigsRatings.average -
        aContentPublisherCraigsRatings.average
      )
    } else if (
      aContentPublisherCraigsRatings &&
      !bContentPublisherCraigsRatings
    ) {
      return true
    } else if (
      bContentPublisherCraigsRatings &&
      !aContentPublisherCraigsRatings
    ) {
      return false
    }
  }

  const sortByAverageRating = idsToSort => {
    const rankedIds = idsToSort.reduce(
      (acc, id) => {
        const contentPublisher = byId[id]
        const { craigsRatings } = contentPublisher
        const hasRankings = Boolean(craigsRatings)
        if (hasRankings) {
          acc.hasRanking.push(id)
        } else {
          acc.noRanking.push(id)
        }
        return acc
      },
      {
        hasRanking: [],
        noRanking: [],
      }
    )
    const sortedRanked = rankedIds.hasRanking.slice().sort(averageRatingSorter)
    const sortedAlpha = rankedIds.noRanking.slice().sort(ascendingAlphaSorter)
    return [...sortedRanked, ...sortedAlpha]
  }

  const displayedIds = filteredIds || allIds

  const getSortedIds = () => {
    const allPremiumIds = displayedIds.filter(displayId =>
      premiumIds.includes(displayId)
    )
    const allNonPremiumIds = displayedIds.filter(
      displayId => !premiumIds.includes(displayId)
    )
    let premiumSorted, nonPremiumSorted
    switch (sortType) {
      case 'A-Z':
        premiumSorted = allPremiumIds.slice().sort(ascendingAlphaSorter)
        nonPremiumSorted = allNonPremiumIds.slice().sort(ascendingAlphaSorter)
        return [...premiumSorted, ...nonPremiumSorted]
      case 'Z-A':
        premiumSorted = allPremiumIds.slice().sort(descendingAlphaSorter)
        nonPremiumSorted = allNonPremiumIds.slice().sort(descendingAlphaSorter)
        return [...premiumSorted, ...nonPremiumSorted]
      case 'updated-newest':
        premiumSorted = allPremiumIds.slice().sort(updatedAtNewSorter)
        nonPremiumSorted = allNonPremiumIds.slice().sort(updatedAtNewSorter)
        return [...premiumSorted, ...nonPremiumSorted]
      case 'updated-oldest':
        premiumSorted = allPremiumIds.slice().sort(updatedAtOldSorter)
        nonPremiumSorted = allNonPremiumIds.slice().sort(updatedAtOldSorter)
        return [...premiumSorted, ...nonPremiumSorted]
      case 'average':
        premiumSorted = sortByAverageRating(allPremiumIds)
        nonPremiumSorted = sortByAverageRating(allNonPremiumIds)
        break
      default:
        //A-Z is the default
        //platinumSorted = allPlatinumIds.slice().sort(ascendingAlphaSorter)
        premiumSorted = allPremiumIds.slice().sort(ascendingAlphaSorter)
        nonPremiumSorted = allNonPremiumIds.slice().sort(ascendingAlphaSorter)
        break
    }

    return [...premiumSorted, ...nonPremiumSorted]
  }
  const sortedIds = getSortedIds()

  const contentPublisherIdsToDisplay = sortedIds.filter(displayId =>
    displayedIds.includes(displayId)
  )
  const publishedContentPublisherIds = contentPublisherIdsToDisplay.filter(
    contentPublisherId => {
      const contentPublisher = byId[contentPublisherId]
      return mode === 'admin' || contentPublisher.isPublished
    }
  )


  const renderContentPublisherCards = () => {
    return publishedContentPublisherIds.reduce((acc, contentPublisherId) => {
      const contentPublisher = byId[contentPublisherId]
      // hide unpublished systems from users
      // const shouldHide =
      //   (mode === 'user' || mode === 'User') && !contentPublisher.isPublished
      // if (!shouldHide) {
      acc.push(
        <LazyLoad
          key={contentPublisherId}
          height={100}
          offset={2000}
          overflow
          once
        >
          <ContentPublisherCardComponent
            contentPublisher={contentPublisher}
            key={contentPublisherId}
          />
        </LazyLoad>
      )
      // }
      return acc
    }, [])
  }


  const resultString =
    publishedContentPublisherIds.length === 1 ? 'result' : 'results'
  const countString = `${publishedContentPublisherIds.length} ${resultString}`
  const isAdmin = mode === 'admin'

  const createAppButton = isAdmin ? <CreateAppButton /> : null

  const isLoaded = hasFetchedApplications && Boolean(allIds)
  const noAppsToShow = publishedContentPublisherIds.length === 0

  return isLoaded ? (
    noAppsToShow ? (
      <>
        <Content>
          <ListHeader>
            <Split>
              <Count>{countString}</Count>
              <SortBy handleSortTypeChange={setSortType} sortType={sortType} />
              {createAppButton}
            </Split>
            <CompareCard />
          </ListHeader>
          <EmptyList>No Content Publishers to Show</EmptyList>
          <TrialDialog />
        </Content>
      </>
    ) : (
        <>
          <Content>
            <ListHeader>
              <Split>
                <Count>{countString}</Count>
                <SortBy handleSortTypeChange={setSortType} sortType={sortType} />
                {createAppButton}
              </Split>
              <CompareCard />
            </ListHeader>
            {renderContentPublisherCards()}
            <TrialDialog />
          </Content>
        </>
      )
  ) : (
      <Loader />
    )
}

export default List
