import produce from 'immer'
import {actionTypes} from './constants'

const initialCounts = {
  fetchedAt: null,
  leads: {
    total: null,
    newThisWeek: null,
    newThisMonth: null,
  },
  searches: {
    total: null,
    newThisWeek: null,
    newThisMonth: null,
  },
  playlistViews: {
    total: null,
    newThisWeek: null,
    newThisMonth: null,
    all: []
  },
  users: {
    total: null,
    admins: null,
    vendors: null,
    newThisWeek: null,
    newThisMonth: null,
  },
  systems: {
    total: null,
    newThisWeek: null,
    newThisMonth: null,
    premium: null,
  },
}

const initialState = {
  counts: initialCounts,
  data: {
    type: null,
    name: null,
    csv: null,
    url: null,
    loaded: false,
    ready: false,
  },
  dialogOpen: false,
  reportType: null,
  options: {
    range: 'all',
    startDate: new Date(),
    endDate: new Date(),
  },
}

const reducer = produce((draft, action) => {
  switch (action.type) {
    case actionTypes.RESET_COUNTS:
      draft.counts = initialCounts
      break

    case actionTypes.FETCH_COUNTS_SUCCEEDED:
      Object.assign(draft.counts, action.payload)
      draft.counts.fetchedAt = new Date()
      break

    case actionTypes.REPORT_DATA_LOADED:
      draft.data.loaded = true
      break

    case actionTypes.OPEN_REPORT_DIALOG:
      draft.reportType = action.payload.reportType
      draft.dialogOpen = true
      break

    case actionTypes.CLOSE_REPORT_DIALOG:
      draft.data.loaded = false
      draft.data.ready = false
      draft.reportType = null
      draft.dialogOpen = false
      break

    case actionTypes.SET_REPORT_OPTIONS:
      if (draft.data.url) window.URL.revokeObjectURL(draft.data.url)

      Object.assign(draft.options, action.payload.options)
      break

    case actionTypes.REPORT_UPDATED:
      Object.assign(draft.data, action.payload.data)
      break

    // no default
  }
}, initialState)

export default reducer
