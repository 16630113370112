import React from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {
  styled,
  Button as MuiButton,
  Dialog as MuiDialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
} from '@material-ui/core'
import {getDialogOpen, getReportType} from 'reports/store/selectors'
import {closeReportDialog} from 'reports/store/actions'
import Range from './Range'
import DownloadButton from './DownloadButton'

const DialogActions = styled(MuiDialogActions)(({theme}) => ({
  margin: theme.spacing(2),
  marginBottom: theme.spacing(1),
}))

const GenerateReportDialog = (props) => {
  const open = useSelector(getDialogOpen)
  const reportType = useSelector(getReportType)
  const dispatchRedux = useDispatch()

  const closeDialog = () => dispatchRedux(closeReportDialog())

  let title = ""
  let maxRangeLabel = "All"
  let dateRangeLabel = "Date Range"

  switch (reportType) {
    case 'leads':
      title = "Generate Leads Report"
      maxRangeLabel = "Last 90 days"
      dateRangeLabel = "Date Range (within the last 90 days)"
      break

    case 'searches':
      title = "Generate Searches Report"
      maxRangeLabel = "Last 90 days"
      dateRangeLabel = "Date Range (within the last 90 days)"
      break
      
    case 'popularSearches':
      title = "Generate Popular Searches Report"
      maxRangeLabel = "Last 90 days"
      dateRangeLabel = "Date Range (within the last 90 days)"
      break

    case 'users':
      title = "Generate Users Report"
      break
      
    case 'contentPlayerViews':
      title = "Generate Content Player Report"
      maxRangeLabel = "Most recent (max 1,000 records)"
      dateRangeLabel = "Date Range (max 1,000 records)"
      break

    case 'popularContentPlayerViews':
      title = "Generate Popular Content Player Report"
      maxRangeLabel = "Most recent (max 1,000 records)"
      dateRangeLabel = "Date Range (max 1,000 records)"
      break 

    default:
      break
  }

  return (
    <MuiDialog
      open={open}
      onClose={closeDialog}
      maxWidth="sm"
      fullWidth
      {...props}
    >
      <MuiDialogTitle>{title}</MuiDialogTitle>
      <MuiDialogContent>
        <Range maxrangelabel={maxRangeLabel} daterangelabel={dateRangeLabel}/>
      </MuiDialogContent>
      <DialogActions>
        <MuiButton color="primary" onClick={closeDialog}>
          Cancel
        </MuiButton>
        <DownloadButton/>
      </DialogActions>
    </MuiDialog>
  )
}

export default GenerateReportDialog
