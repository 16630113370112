import React from 'react'
import { styled } from '@material-ui/core'
import SaveButton from 'contentPublisher/components/SaveButton'
import DemoButton from 'trials/components/Button'
import TrialDialog from 'trials/components/Dialog'
import { useCompareData } from './hooks'
import Row from './Row'
import Logo from './Logo'

// this attempts to account for the width of the scrollbar so that centered content looks aligned
const ScrollbarSpacer = styled('div')({
  paddingRight: 18,
})

const Title = styled('div')(({ theme }) => ({
  fontSize: 20,
  fontWeight: 500,
}))

const StyledRow = styled(Row)(({ theme }) => ({
  justifyItems: 'center',
  alignItems: 'center',
}))

const Name = styled('div')(({ theme }) => ({
  fontSize: 18,
  fontWeight: 500,
  lineHeight: '1.3em',
  color: theme.palette.primary.main,
  textAlign: 'center',
}))

const Actions = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}))

const TableHeader = props => {
  const { systems } = useCompareData()

  return (
    <ScrollbarSpacer>
      <Title>Compare Content Publishers</Title>
      <StyledRow>
        {systems.map(s => (
          <Logo key={s.id} src={s?.thumbnail?.uri} />
        ))}
      </StyledRow>
      <StyledRow>
        {systems.map(s => (
          <Name key={s.id}>{s?.name}</Name>
        ))}
      </StyledRow>
      <StyledRow>
        {systems.map(sys => {
          const demoButton = sys.trial ? (
            <DemoButton systemId={sys.id} />
          ) : null
          return (
            <Actions key={sys.id}>
              <SaveButton contentPublisher={sys} />
              {demoButton}
            </Actions>
          )
        })}
      </StyledRow>
      <TrialDialog />
    </ScrollbarSpacer>
  )
}

export default TableHeader
