import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useCurrentUser } from 'users/hooks'
import { styled, Button as MuiButton } from '@material-ui/core'
// import MuiRFPIcon from '@material-ui/icons/Description'
// import MuiCheckIcon from '@material-ui/icons/Check'
import Tooltip from '@material-ui/core/Tooltip'
import { openTrialDialog } from 'trials/store/actions'
import { getCurrentUser } from 'users/store/selectors'
//import { useMode } from 'app/hooks'

const Button = styled(MuiButton)(({ theme }) => ({
  '&:disabled': {
    color: theme.palette.success.main,
  },
}))

// const RFPIcon = styled(MuiRFPIcon)(({ theme }) => ({
//   marginRight: theme.spacing(1),
// }))

// const CheckIcon = styled(MuiCheckIcon)(({ theme }) => ({
//  marginRight: theme.spacing(1),
// }))

const TrialButton = ({ systemId, ...props }) => {
  //const [mode] = useMode()
  const user = useCurrentUser()
  const currentUser = useSelector(getCurrentUser)
  const dispatchRedux = useDispatch()

  const isGuestUser = !user

  let buttonText = 'Request Trial'
  let disabled = false
  let hasRequestedQuote = false
  //let buttonIcon = <RFPIcon />

  if (
    currentUser &&
    currentUser.trials &&
    currentUser.trials.some(r => r.application === systemId)
  ) {
    buttonText = 'Quote Requested'
    disabled = true
    hasRequestedQuote = true
    //buttonIcon = <CheckIcon />
  }

  const handleClick = event => {
    event.preventDefault()
    event.stopPropagation()
    if (!isGuestUser) {
      dispatchRedux(openTrialDialog(systemId))
    }
  }

  return (
    <Tooltip
      title='Create a FREE account to access this feature'
      disableHoverListener={!isGuestUser}
    >
      <Button
        color='primary'
        variant='outlined'
        onClick={handleClick}
        disabled={disabled}
        style={{
          color: isGuestUser
            ? '#D3D3D3'
            : hasRequestedQuote
            ? 'rgb(67, 160, 71)'
            : '',
          border: `1px solid ${
            isGuestUser || hasRequestedQuote ? '#D3D3D3' : ''
          }`,
        }}
        {...props}
      >
        {buttonText}
      </Button>
    </Tooltip>
  )
}

export default TrialButton
