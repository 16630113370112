import { buffers, eventChannel } from 'redux-saga'
import {
  all,
  call,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects'
import feathersClient from 'utils/feathers'
import { actionTypes as userActionTypes } from 'users/store/constants'
import { editApplicationChange } from './actions'
import { actionTypes } from './constants'
import { getEditApplicationState } from './selectors'
import { postMessage } from 'app/store/actions'

const contentPublisherService = feathersClient.service('contentpublishers')
const durationService = feathersClient.service('duration')
const deliveryService = feathersClient.service('delivery')
const featuresService = feathersClient.service('features')
const publisherService = feathersClient.service('publisher')
const filesService = feathersClient.service('files')
const leadService = feathersClient.service('leads')
const usersService = feathersClient.service('users')
const contentTypeService = feathersClient.service('content')
const subjectTypeService = feathersClient.service('subject')
const languageService = feathersClient.service('languages')
const courseStandardService = feathersClient.service('coursestandards');
const playlistItemService = feathersClient.service('playlistItem')
const playlistItemMetricsService = feathersClient.service('playlistItemMetrics')

function* fetchApplicationsSaga(action) {
  try {
    // TODO: handle pagination properly
    const results = yield call([contentPublisherService, 'find'], {
      query: { $limit: 200 },
    })
    yield put({
      type: actionTypes.FETCH_APPLICATIONS_SUCCEEDED,
      payload: { results },
    })
  } catch (error) {
    yield put({
      type: actionTypes.FETCH_APPLICATIONS_FAILED,
      payload: { error },
    })
    yield console.log({ error })
  }
}

function* fetchDurationSaga(action) {
  try {
    const results = yield call([durationService, 'find'])
    yield put({
      type: actionTypes.FETCH_DURATIONS_SUCCEEDED,
      payload: { results },
    })
  } catch (error) {
    yield put({
      type: actionTypes.FETCH_DURATIONS_FAILED,
      payload: { error },
    })
    yield console.log({ error })
  }
}

function* fetchCourseStandardSaga(action) {
  try {
    const results = yield call([courseStandardService, 'find'])
    yield put({
      type: actionTypes.FETCH_COURSE_STANDARD_SUCCEEDED,
      payload: { results },
    })
  } catch (error) {
    yield put({
      type: actionTypes.FETCH_COURSE_STANDARD_FAILED,
      payload: { error },
    })
    yield console.log({ error })
  }
}

function* fetchLaguagesSaga(action) {
  try {
    const results = yield call([languageService, 'find'])
    yield put({
      type: actionTypes.FETCH_LANGUAGES_SUCCEEDED,
      payload: { results },
    })
  } catch (error) {
    yield put({
      type: actionTypes.FETCH_LANGUAGES_FAILED,
      payload: { error },
    })
    yield console.log({ error })
  }
}

function* fetchContentTypeSaga(action) {
  try {
    const results = yield call([contentTypeService, 'find'])
    yield put({
      type: actionTypes.FETCH_CONTENT_TYPES_SUCCEEDED,
      payload: { results },
    })
  } catch (error) {
    yield put({
      type: actionTypes.FETCH_CONTENT_TYPES_FAILED,
      payload: { error },
    })
    yield console.log({ error })
  }
}

function* fetchSubjectTypeSaga(action) {
  try {
    const results = yield call([subjectTypeService, 'find'])
    yield put({
      type: actionTypes.FETCH_SUBJECT_TYPES_SUCCEEDED,
      payload: { results },
    })
  } catch (error) {
    yield put({
      type: actionTypes.FETCH_SUBJECT_TYPES_FAILED,
      payload: { error },
    })
    yield console.log({ error })
  }
}

function* fetchDeliverySaga(action) {
  try {
    const results = yield call([deliveryService, 'find'])
    yield put({
      type: actionTypes.FETCH_DELIVERY_TYPES_SUCCEEDED,
      payload: { results },
    })
  } catch (error) {
    yield put({
      type: actionTypes.FETCH_DELIVERY_TYPES_FAILED,
      payload: { error },
    })
    yield console.log({ error })
  }
}

function* fetchFeaturesSaga(action) {
  try {
    const results = yield call([featuresService, 'find'])
    yield put({
      type: actionTypes.FETCH_FEATURES_SUCCEEDED,
      payload: { results },
    })
  } catch (error) {
    yield put({ type: actionTypes.FETCH_FEATURES_FAILED, payload: { error } })
    yield console.log({ error })
  }
}

function* fetchPublisherSaga(action) {
  try {
    const results = yield call([publisherService, 'find'])
    yield put({
      type: actionTypes.FETCH_PUBLISHERS_SUCCEEDED,
      payload: { results },
    })
  } catch (error) {
    yield put({ type: actionTypes.FETCH_PUBLISHERS_FAILED, payload: { error } })
    yield console.log({ error })
  }
}

function* saveApplicationSaga(action) {
  try {
    const { data } = yield select(getEditApplicationState)
    const updatedData = {
      ...data,
      thumbnail: data.thumbnail ? data.thumbnail.id : undefined,
      caseStudies: data.caseStudies
        ? data.caseStudies.map(cs => cs.id)
        : undefined,
      playlistItems: data.playlistItems
        ? data.playlistItems.map(cs => cs.id)
        : undefined,
      updatedAt: new Date().toISOString(),
    }
    let results, message
    if (data._id) {
      // update existing application
      results = yield call(
        [contentPublisherService, 'update'],
        data._id,
        updatedData
      )
      message = `${results.name} successfully updated`
    } else {
      // create application
      results = yield call([contentPublisherService, 'create'], updatedData)
      message = `${results.name} successfully created`
    }

    yield put({
      type: actionTypes.SAVE_APPLICATION_SUCCEEDED,
      payload: results,
    })
    yield put(postMessage({ message }))

    yield put({ type: userActionTypes.UPDATE_VENDOR_ADMINS, payload: results })
  } catch (error) {
    yield put({ type: actionTypes.SAVE_APPLICATION_FAILED, payload: { error } })
  }
}

function* deleteApplicationSaga(action) {
  try {
    const { data } = yield select(getEditApplicationState)
    const applicationIdToRemove = data._id
    yield call(
      [contentPublisherService, 'remove'],
      applicationIdToRemove
    )
    yield call([leadService, 'remove'], null, {
      query: { application: applicationIdToRemove },
    })
    yield call(
      [usersService, 'patch'],
      null,
      { $pull: { applications: { $in: [`${applicationIdToRemove}`] } } },
      {
        query: {
          applications: { $in: [`${applicationIdToRemove}`] },
        },
      }
    )
  } catch (error) {
    yield put({ type: actionTypes.SAVE_APPLICATION_FAILED, payload: { error } })
  }
}

function* uploadFileSaga(action) {
  try {
    const {category, file, title,description} = action.payload


    const data = {
      category,
      name: file.name,
      mimeType: file.type,
      size: file.size,
      title,
      description
    }

    const results = yield call([filesService, 'create'], data)

    const { _id: id, uploadUrl, ...fileData } = results

    if (category === 'application/caseStudy') {
      yield put({
        type: actionTypes.EDIT_APPLICATION_ADD_CASE_STUDY,
        payload: {id, loaded: 0, ...fileData}
      })

      yield put({
        type: actionTypes.SAVE_PLAYLIST_ITEM_REQUESTED,
        payload: {
          title,
          description,
          file: {
            id,
            ...fileData
          }
        }
      })

    } else if (category === 'application/thumbnail') {
      yield put(
        editApplicationChange({ thumbnail: { id, loaded: 0, ...fileData } })
      )
    }

    if (uploadUrl) {
      const channel = yield call(uploadFileChannel, results.uploadUrl, file)

      while (true) {
        const { loaded, success, error, done } = yield take(channel)

        if (loaded) {
          if (category === 'application/caseStudy') {
            yield put({
              type: actionTypes.EDIT_APPLICATION_UPDATE_CASE_STUDY,
              payload: { id, loaded },
            })
          } else if (category === 'application/thumbnail') {
            yield put(
              editApplicationChange({ thumbnail: { id, loaded, ...fileData } })
            )
          }
        }

        if (success) {
          if (category === 'application/caseStudy') {
            yield put({
              type: actionTypes.EDIT_APPLICATION_UPDATE_CASE_STUDY,
              payload: { id, loaded: undefined },
            })
          } else if (category === 'application/thumbnail') {
            yield put(
              editApplicationChange({
                thumbnail: { id, loaded: file.size, ...fileData },
              })
            )
          }
        }

        if (error) {
          console.log({ file: file.name, error })
        }

        if (done) break
      }
    }
  } catch (error) {
    console.log({ error })
  }
}

function * uploadFile (uploadUrl,file, size, id){
  const channel = yield call(uploadFileChannel, uploadUrl, file)
  while (true) {
    const {loaded, error, done} = yield take(channel)
    yield put({
      type: actionTypes.UPLOADING_FILE,
      payload: {uploadUrl, id, loaded, size, done}
    })
    if (done || error)
      break
  }
}

function* savePlaylistItemSaga (action) {
  try {
    const {file, thumbnail, embedUrl, title, description} = action.payload

    let fileId,fileUploadUrl,fileCreationResults
    let thumbnailId,thumbnailUploadUrl,thumbnailCreationResults

    if (thumbnail) {
      const data = {
        category: 'playlist/thumbnail',
        name: thumbnail.name,
        mimeType: thumbnail.type,
        size: thumbnail.size
      }
      const thumbnailResults = yield call([filesService, 'create'], data)
      const {_id, uploadUrl, ...fileResults} = thumbnailResults
      thumbnailId = thumbnailResults._id
      thumbnailUploadUrl= thumbnailResults.uploadUrl
      thumbnailCreationResults = fileResults
    }

    if (file) {
      const data = {
        category: 'application/caseStudy',
        name: file.name,
        mimeType: file.type,
        size: file.size
      }
      const results = yield call([filesService, 'create'], data)
      const {_id, uploadUrl, ...fileResults} = results
      fileId = results._id
      fileUploadUrl = results.uploadUrl
      fileCreationResults = fileResults
    }

    const playListItemResults = yield call([playlistItemService, 'create'], {title,description, embedUrl, file: fileId, thumbnail : thumbnailId})

    yield put({
      type: actionTypes.EDIT_APPLICATION_ADD_PLAYLIST_ITEM,
      payload: {id: playListItemResults._id, ...playListItemResults, ...action.payload, file: {id: fileId, ...fileCreationResults}, thumbnail : {id: thumbnailId, ...thumbnailCreationResults}}
    })

    if (fileUploadUrl){
      yield uploadFile(fileUploadUrl,file,file.size,fileId)
    }
    if (thumbnailUploadUrl){
      yield uploadFile(thumbnailUploadUrl,thumbnail, thumbnail.size, thumbnailId)
    }
  } catch (error) {
    console.log({error})
  }
}

function * editPlaylistItemSaga (action) {
  try {
    const {title, description, embedUrl, _id, file, existingFileId, thumbnail, existingThumbnailId} = action.payload
    let fileId = existingFileId
    let thumbnailId = existingThumbnailId

    let fileUploadUrl,fileCreationResults
    let thumbnailUploadUrl,thumbnailCreationResults

    if (thumbnail && (thumbnail?.id !== existingThumbnailId || existingThumbnailId === undefined)) {
      const data = {
        category: 'playlist/thumbnail',
        name: thumbnail.name,
        mimeType: thumbnail.type,
        size: thumbnail.size
      }
      const thumbnailResults = yield call([filesService, 'create'], data)
      const {_id, uploadUrl, ...fileResults} = thumbnailResults
      thumbnailId = thumbnailResults._id
      thumbnailUploadUrl= thumbnailResults.uploadUrl
      thumbnailCreationResults = fileResults
    }

    if (file && file?.id !== existingFileId) {
      const data = {
        category: 'application/caseStudy',
        name: file.name,
        mimeType: file.type,
        size: file.size
      }
      const results = yield call([filesService, 'create'], data)
      const {_id, uploadUrl, ...fileResults} = results
      fileId = results._id
      fileUploadUrl = results.uploadUrl
      fileCreationResults = fileResults
    }
    const results = yield call([playlistItemService, 'update'], _id, {title, description, embedUrl, file: fileId,thumbnail: thumbnailId })

    const fileContents = fileCreationResults ? fileCreationResults : file
      const thumbnailContents = thumbnailCreationResults ? thumbnailCreationResults : thumbnail

    yield put({type: actionTypes.EDIT_PLAYLIST_ITEM_SUCCEEDED, payload: {id: results._id, ...results, file: {id: fileId, ...fileContents}, thumbnail : {id: thumbnailId, ...thumbnailContents}}})

    if (fileUploadUrl){
      yield uploadFile(fileUploadUrl,file,file.size,fileId)
    }
    if (thumbnailUploadUrl){
      yield uploadFile(thumbnailUploadUrl,thumbnail, thumbnail.size, thumbnailId)
    }
  } catch (error) {
    yield put({
      type: actionTypes.EDIT_PLAYLIST_ITEM_FAILED
    })
    console.log({error})
  }
}

function * deletePlaylistItemSaga (action) {
  try {
    const id = action.payload

    const results = yield call([playlistItemService, 'remove'], id)

    yield put({
      type: actionTypes.DELETE_PLAYLIST_ITEM_SUCCEEDED,
      payload: {id: id, ...results}
    })
  }
  catch(error){
    yield put({
      type: actionTypes.DELETE_PLAYLIST_ITEM_FAILED
    })
    console.log({error})
  }
}

function * savePlaylistItemOrderSaga (action) {
  try {
    const newItems = action.payload.map((item,index) => {
      return {
        ...item,
        order: index
      }
    })

    for(let i =0; i < newItems.length; i++) {
      yield call([playlistItemService, 'patch'], newItems[i]._id, {order : newItems[i].order})
    }

    yield put({
      type: actionTypes.SAVE_PLAYLIST_ITEM_ORDER_SUCCEEDED,
      payload: newItems
    })
  } catch (error) {
    console.log({error})
  }
}

function * trackPlaylistItemViewSaga (action) {
  //Every time an end user views a playlist item we want to track that action.
  //We want to store the userId, playlistItemId, and the duration view (if a video)?

  const view = {
    user: action.payload.user.id,
    playlistItem: action.payload.item.id,
    application: action.payload.applicationId,
    duration: action.payload.duration
  }
  yield call([playlistItemMetricsService, 'create'], view)

}

function * fetchPlaylistItemViewsSaga(action){
  const { mode, user }= action.payload
  const playlistViewsQuery = {
    application: mode === 'vendor' ? {$in: user.applications} : undefined,
    $limit: 10000,
    $sort: {createdAt: -1},
  }

  const results = yield call([playlistItemMetricsService, 'find'], {query: playlistViewsQuery})
  yield put({
    type: actionTypes.FETCH_PLAYLIST_ITEM_VIEWS_SUCCEEDED,
    payload: { results, completed: true }
  })

}

function uploadFileChannel (url, file) {
  return eventChannel(emit => {
    const xhr = new XMLHttpRequest()

    xhr.upload.onloadstart = () => emit({loaded: 0})
    xhr.upload.onprogress = ({loaded}) => emit({loaded})
    xhr.upload.onabort = () => emit({error: new Error('File upload aborted')})
    xhr.upload.onerror = () => emit({error: new Error('File upload failed')})
    xhr.upload.ontimeout = () =>
      emit({error: new Error('File upload timed out')})
    xhr.upload.onload = () => emit({success: true})
    xhr.upload.onloadend = () => emit({done: true})

    xhr.open('PUT', url)
    xhr.send(file)

    return () => xhr.abort()
  }, buffers.sliding(2))
}

function* watchFetchApplications() {
  yield takeLatest(
    actionTypes.FETCH_APPLICATIONS_REQUESTED,
    fetchApplicationsSaga
  )
}

function* watchFetchDurations() {
  yield takeLatest(actionTypes.FETCH_DURATIONS_REQUESTED, fetchDurationSaga)
}

function* watchFetchContentTypes() {
  yield takeLatest(
    actionTypes.FETCH_CONTENT_TYPES_REQUESTED,
    fetchContentTypeSaga
  )
}

function* watchFetchFeatures() {
  yield takeLatest(actionTypes.FETCH_FEATURES_REQUESTED, fetchFeaturesSaga)
}

function* watchFetchSubjectTypes() {
  yield takeLatest(
    actionTypes.FETCH_SUBJECT_TYPES_REQUESTED,
    fetchSubjectTypeSaga
  )
}

function* watchFetchDeliveryTypes() {
  yield takeLatest(
    actionTypes.FETCH_DELIVERY_TYPES_REQUESTED,
    fetchDeliverySaga
  )
}

function* watchFetchCourseStandards() {
  yield takeLatest(
    actionTypes.FETCH_COURSE_STANDARD_REQUESTED,
    fetchCourseStandardSaga
  )
}

function* watchFetchLanguages() {
  yield takeLatest(actionTypes.FETCH_LANGUAGES_REQUESTED, fetchLaguagesSaga)
}

function* watchFetchPublishers() {
  yield takeLatest(actionTypes.FETCH_PUBLISHERS_REQUESTED, fetchPublisherSaga)
}

function* watchSaveApplication() {
  yield takeLatest(actionTypes.SAVE_APPLICATION_REQUESTED, saveApplicationSaga)
}

function* watchDeleteApplication() {
  yield takeLatest(
    actionTypes.DELETE_APPLICATION_REQUESTED,
    deleteApplicationSaga
  )
}
function * watchUploadFile () {
  yield takeEvery(actionTypes.UPLOAD_FILE_REQUESTED, uploadFileSaga)
}

function * watchSavePlayListItem () {
  yield takeEvery(actionTypes.SAVE_PLAYLIST_ITEM_REQUESTED, savePlaylistItemSaga)
}

function * watchEditPlayListItem () {
  yield takeEvery(actionTypes.EDIT_PLAYLIST_ITEM_REQUESTED, editPlaylistItemSaga)
}

function * watchDeletePlayListItem () {
  yield takeEvery(actionTypes.DELETE_PLAYLIST_ITEM_REQUESTED, deletePlaylistItemSaga)
}

function * watchSavePlayListItemOrder () {
  yield takeEvery(actionTypes.SAVE_PLAYLIST_ITEM_ORDER_REQUESTED, savePlaylistItemOrderSaga)
}
function * watchTrackPlayListItemView() {
  yield takeEvery(actionTypes.TRACK_PLAYLIST_ITEM_VIEW_REQUESTED,trackPlaylistItemViewSaga)
}

function * watchFetchPlaylistItemViews() {
  yield takeEvery(actionTypes.FETCH_PLAYLIST_ITEM_VIEWS_REQUESTED,fetchPlaylistItemViewsSaga)
}


function* rootSaga() {
  yield all([
    watchFetchApplications(),
    watchFetchFeatures(),
    watchFetchPlaylistItemViews(),
    watchSaveApplication(),
    watchUploadFile(),
    watchDeleteApplication(),
    watchFetchDurations(),
    watchFetchContentTypes(),
    watchFetchLanguages(),
    watchFetchSubjectTypes(),
    watchFetchDeliveryTypes(),
    watchFetchPublishers(),
    watchFetchCourseStandards(),
    watchSavePlayListItem(),
    watchEditPlayListItem(),
    watchSavePlayListItemOrder(),
    watchDeletePlayListItem(),
    watchTrackPlayListItemView()
  ])
}

export default rootSaga
