import produce from 'immer'
import {
  select,
  call,
  put,
  takeLeading,
  takeLatest,
  takeEvery,
  all
} from 'redux-saga/effects'
import feathersClient from 'utils/feathers'
import { postMessage, setMode } from 'app/store/actions'
import { initialFilters } from 'searches/store/constants'
import {
  updateCurrentSearchFilters,
  saveCurrentSearch
} from 'searches/store/actions'
import { login, sendPasswordReset, fetchUsers } from './actions'
import {
  fetchApplications,
  fetchFeatures,
  fetchDurations
} from 'contentPublisher/store/actions'
import { actionTypes } from './constants'
import { getCurrentUser, getUsers } from './selectors'

const usersService = feathersClient.service('users')
const authManagementService = feathersClient.service('authManagement')
const guestConsenstsService = feathersClient.service('guestconsents')

function * loginSaga (action) {
  const { credentials } = action.payload

  try {
    const auth = yield call(feathersClient.authenticate, credentials)
				yield put({ type: actionTypes.LOGIN_SUCCEEDED, payload: { auth } })

    // set the mode
    yield put(setMode(auth.user.role))

    // refetch LMS data
    yield put(fetchApplications())
    yield put(fetchFeatures())
    yield put(fetchDurations())

    if (auth.user.role === 'admin' || auth.user.role === 'vendor') yield put(fetchUsers())

    if (auth.user.latestSearch) {
      const { id, filters } = auth.user.latestSearch

      // merge saved filters with blank filters
      const mergedFilters = yield produce(initialFilters, draft =>
        Object.assign(draft, filters)
      )

      yield put(updateCurrentSearchFilters(mergedFilters, id))
    }
  } catch (error) {
    if (credentials) {
      // failed login using email/password
      yield put({ type: actionTypes.LOGIN_FAILED, payload: { error } })
    } else {
      // failed login using jwt
      yield put({ type: actionTypes.LOGOUT_COMPLETED })
    }
  }
}

function * logoutSaga () {
  try {
    yield call(feathersClient.logout)
  } catch (error) {
    // yield console.log({error})
  } finally {
    yield put({ type: actionTypes.LOGOUT_COMPLETED })
    // clear search on logout
    yield put(updateCurrentSearchFilters(initialFilters))
  }
}

function * createUserSaga (action) {
  const isUserRegistering = action.payload.isUserRegistering
  try {
    if (isUserRegistering) {
      yield call([usersService, 'create'], action.payload.user, {
        query: { isSelfRegistering: true }
      })
      yield put(
        login({
          strategy: 'local',
          email: action.payload.user.email,
          password: action.payload.user.password
        })
      )
    } else {
      const user = yield call([usersService, 'create'], action.payload.user)
      yield put({ type: actionTypes.CREATE_USER_SUCCEEDED, payload: { user } })
    }
  } catch (error) {
    console.log({ error })
    yield put({ type: actionTypes.CREATE_USER_FAILED, payload: { error } })
  }
}

function * patchUserSaga (action) {
  try {
    const user = yield call(
      [usersService, 'patch'],
      action.payload.user.id,
      action.payload.user
    )
    yield put({ type: actionTypes.PATCH_USER_SUCCEEDED, payload: { user } })
    yield put(postMessage({ message: 'User updated successfully' }))
  } catch (error) {
    yield put({ type: actionTypes.PATCH_USER_FAILED, payload: { error } })
  }
}

function * removeUserSaga (action) {
  try {
    const user = yield call([usersService, 'remove'], action.payload.id)
    yield put({ type: actionTypes.REMOVE_USER_SUCCEEDED, payload: { user } })
    yield put(postMessage({ message: 'User deleted' }))
  } catch (error) {
    yield put({ type: actionTypes.REMOVE_USER_FAILED, payload: { error } })
  }
}

function * sendInviteEmailSaga (action) {
  yield call([usersService, 'patch'], action.payload.id, {
    sendInviteEmail: true
  })
  yield put(postMessage({ message: 'Invitation email sent' }))
}

function * sendPasswordResetSaga (action) {
  try {
    yield call([authManagementService, 'create'], {
      action: 'sendResetPwd',
      ...action.payload
    })
  } catch (error) {
    // ignore errors for security
  }
}

function * addGuestCookieConsentSaga (action) {
  const { ipAddress, method, accepted } = action.payload
  try {
    yield call([guestConsenstsService, 'create'], {
      accepted,
      ipAddress,
      how: method
    })
  } catch (error) {
    // handle error
  }
}

function * updatePasswordSaga (action) {
  const { password, token, email, oldPassword } = action.payload

  const params = {}
  if (oldPassword) {
    params.action = 'passwordChange'
    params.value = { user: { email }, oldPassword, password }
  } else if (email) {
    params.action = 'resetPwdShort'
    params.value = { user: { email }, token, password }
  } else {
    params.action = 'resetPwdLong'
    params.value = { token, password }
  }

  try {
    const user = yield call([authManagementService, 'create'], params)

    // login user
    yield put(login({ strategy: 'local', email: user.email, password }))

    yield put({
      type: actionTypes.UPDATE_PASSWORD_SUCCEEDED,
      payload: { user }
    })
  } catch (error) {
    yield put({ type: actionTypes.UPDATE_PASSWORD_FAILED, payload: { error } })

    // send new password reset email
    yield put(sendPasswordReset(email, { includeShortToken: true }))
  }
}

function * saveUserList (action) {
  const state = yield select()
  const user = getCurrentUser(state)

  const applications = new Set(user.applications)

  switch (action.type) {
    case actionTypes.ADD_LMS_TO_LIST:
      action.payload.forEach(id => applications.add(id))
      // save the search
      yield put(saveCurrentSearch("System added to user's list"))
      break

    case actionTypes.REMOVE_LMS_FROM_LIST:
      applications.delete(action.payload)
      break

    // no default
  }

  try {
    const updatedUser = yield call([usersService, 'patch'], user.id, {
      applications: Array.from(applications)
    })
    yield put({
      type: actionTypes.UPDATE_CURRENT_USER_SUCCEEDED,
      payload: updatedUser
    })
  } catch (error) {
    yield console.log({ error })
  }
}

function * refetchCurrentUserSaga () {
  const currentUser = yield select(getCurrentUser)

  try {
    const updatedUser = yield call([usersService, 'get'], currentUser.id)
    yield put({
      type: actionTypes.UPDATE_CURRENT_USER_SUCCEEDED,
      payload: updatedUser
    })

    if (updatedUser.latestSearch) {
      // const { id, filters } = updatedUser.latestSearch
      //
      //  // merge saved filters with blank filters
      // const mergedFilters = yield produce(initialFilters, draft =>
        // Object.assign(draft, filters)
      // )

      // yield put(updateCurrentSearchFilters(mergedFilters, id))
    }
  } catch (error) {
    yield console.log({ error })
  }
}

function * updateCurrentUserSaga (action) {
  const { user, message } = action.payload
  const currentUser = yield select(getCurrentUser)
		const userId = currentUser.id || currentUser._id

  try {
    const updatedUser = yield call([usersService, 'patch'], userId, user)
    yield put({
      type: actionTypes.UPDATE_CURRENT_USER_SUCCEEDED,
      payload: updatedUser
    })
    yield put(
      postMessage({ message: message || 'Profile successfully updated' })
    )
  } catch (error) {
    yield put(
      postMessage({ message: 'Error updating your profile', isError: true })
    )
    yield console.log({ error })
  }
}

function * fetchUsersSaga (action) {
  const { limit, skip } = action.payload

  try {
    const results = yield call([usersService, 'find'], {
      query: { $limit: limit, $skip: skip, $sort: 'lastName' }
    })

    const fetchedCount = skip + limit
    const completed = fetchedCount >= results.total

    yield put({
      type: actionTypes.FETCH_USERS_SUCCEEDED,
      payload: { results, completed }
    })

    if (!completed) yield put(fetchUsers({ limit, skip: fetchedCount }))
  } catch (error) {
    yield put({ type: actionTypes.FETCH_USERS_FAILED, payload: { error } })
  }
}

function * updateVendorAdminsSaga (action) {
  const application = action.payload
  const appId = application._id
  const adminIds = new Set(application.admins)

  try {
    const { data } = yield select(getUsers)

    for (const id of data.allIds) {
      const user = data.byId[id]
      const appIds = new Set(user.applications)

      let changes

      if (adminIds.has(id) && !appIds.has(appId)) {
        // add application to user's list and upgrade role
        appIds.add(appId)

        changes = {
          applications: Array.from(appIds),
          role: user.role === 'user' ? 'vendor' : undefined
        }
      }

      if (appIds.has(appId) && !adminIds.has(id)) {
        // remove application from user's list
        appIds.delete(appId)

        changes = {
          applications: Array.from(appIds)
        }
      }

      if (changes) {
        // update the user
        const updatedUser = yield call([usersService, 'patch'], id, changes)
        yield put({
          type: actionTypes.PATCH_USER_SUCCEEDED,
          payload: { user: updatedUser }
        })

        const currentUser = yield select(getCurrentUser)
        if (id === currentUser.id)
          yield put({
            type: actionTypes.UPDATE_CURRENT_USER_SUCCEEDED,
            payload: updatedUser
          })
      }
    }
  } catch (error) {
    console.log({ error })
  }
}

function * watchLogin () {
  yield takeLatest(actionTypes.LOGIN_REQUESTED, loginSaga)
}

function * watchLogout () {
  yield takeLeading(actionTypes.LOGOUT_REQUESTED, logoutSaga)
}

function * watchCreateUser () {
  yield takeLeading(actionTypes.CREATE_USER_REQUESTED, createUserSaga)
}

function * watchPatchUser () {
  yield takeLeading(actionTypes.PATCH_USER_REQUESTED, patchUserSaga)
}

function * watchRemoveUser () {
  yield takeLeading(actionTypes.REMOVE_USER_REQUESTED, removeUserSaga)
}

function * watchSendInviteSaga () {
  yield takeLeading(actionTypes.SEND_INVITE_EMAIL, sendInviteEmailSaga)
}

function * watchSendPasswordReset () {
  yield takeLeading(actionTypes.SEND_PASSWORD_RESET, sendPasswordResetSaga)
}

function * watchUpdatePasswordSaga () {
  yield takeLeading(actionTypes.UPDATE_PASSWORD_REQUESTED, updatePasswordSaga)
}

function * watchUpdateLmsList () {
  const updateLmsActions = [
    actionTypes.ADD_LMS_TO_LIST,
    actionTypes.REMOVE_LMS_FROM_LIST
  ]

  yield takeEvery(updateLmsActions, saveUserList)
}

function * watchRefetchCurrentUser () {
  yield takeLatest(
    actionTypes.REFETCH_CURRENT_USER_REQUESTED,
    refetchCurrentUserSaga
  )
}

function * watchUpdateCurrentUser () {
  yield takeLatest(
    actionTypes.UPDATE_CURRENT_USER_REQUESTED,
    updateCurrentUserSaga
  )
}

function * watchFetchUsers () {
  yield takeLatest(actionTypes.FETCH_USERS_REQUESTED, fetchUsersSaga)
}

function * watchUpdateVendorAdmins () {
  yield takeEvery(actionTypes.UPDATE_VENDOR_ADMINS, updateVendorAdminsSaga)
}

function * watchUpdateGuestCookieConsent () {
  yield takeEvery(
    actionTypes.UPDATE_GUEST_COOKIE_CONSENT,
    addGuestCookieConsentSaga
  )
}

function * rootSaga () {
  yield all([
    watchLogin(),
    watchLogout(),
    watchCreateUser(),
    watchPatchUser(),
    watchRemoveUser(),
    watchSendInviteSaga(),
    watchSendPasswordReset(),
    watchUpdatePasswordSaga(),
    watchUpdateLmsList(),
    watchRefetchCurrentUser(),
    watchUpdateCurrentUser(),
    watchFetchUsers(),
    watchUpdateVendorAdmins(),
    watchUpdateGuestCookieConsent()
  ])
}

export default rootSaga
