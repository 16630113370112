import { actionTypes } from './constants'

export const fetchApplications = () => ({
  type: actionTypes.FETCH_APPLICATIONS_REQUESTED,
})

export const fetchFeatures = () => ({
  type: actionTypes.FETCH_FEATURES_REQUESTED,
})

export const fetchContentTypes = () => ({
  type: actionTypes.FETCH_CONTENT_TYPES_REQUESTED,
})

export const fetchDurations = () => ({
  type: actionTypes.FETCH_DURATIONS_REQUESTED,
})

export const fetchSubjectTypes = () => ({
  type: actionTypes.FETCH_SUBJECT_TYPES_REQUESTED,
})

export const fetchDeliveryTypes = () => ({
  type: actionTypes.FETCH_DELIVERY_TYPES_REQUESTED,
})

export const fetchCourseStandards = () => ({
  type: actionTypes.FETCH_COURSE_STANDARD_REQUESTED,
})

export const fetchLanguages = () => ({
  type: actionTypes.FETCH_LANGUAGES_REQUESTED,
})

export const fetchPublishers = () => ({
  type: actionTypes.FETCH_PUBLISHERS_REQUESTED,
})

export const editApplicationBegin = id => ({
  type: actionTypes.EDIT_APPLICATION_BEGIN,
  payload: {
    id,
  },
})

export const editApplicationChange = changes => ({
  type: actionTypes.EDIT_APPLICATION_CHANGE,
  payload: {
    ...changes,
  },
})

export const savePlaylistItemOrder = newList => ({
  type: actionTypes.SAVE_PLAYLIST_ITEM_ORDER_REQUESTED,
  payload: newList
})

export const saveApplication = () => ({
  type: actionTypes.SAVE_APPLICATION_REQUESTED,
})

export const deleteApplication = () => ({
  type: actionTypes.DELETE_APPLICATION_REQUESTED,
})

export const addToUploadQueue = upload => ({
  type: actionTypes.UPLOAD_FILE_REQUESTED,
  payload: upload,
})

export const savePlaylistItem = playlistItem => ({
  type: actionTypes.SAVE_PLAYLIST_ITEM_REQUESTED,
  payload: playlistItem
})

export const trackPlaylistItemView = playlistItem => ({
  type: actionTypes.TRACK_PLAYLIST_ITEM_VIEW_REQUESTED,
  payload: playlistItem
})

export const fetchPlaylistItemViews = (payload) => ({
  type: actionTypes.FETCH_PLAYLIST_ITEM_VIEWS_REQUESTED,
  payload
})
export const editPlaylistItem = playlistItem => ({
  type: actionTypes.EDIT_PLAYLIST_ITEM_REQUESTED,
  payload: playlistItem
})

export const deletePlaylistItem = id => ({
  type: actionTypes.DELETE_PLAYLIST_ITEM_REQUESTED,
  payload: id
})


export const viewApplicationDetails = id => ({
  type: actionTypes.VIEW_CONTENT_PROVIDER_DETAILS,
  payload: {
    id,
  },
})

export const viewApplicationContactInfo = id => ({
  type: actionTypes.VIEW_APPLICATION_CONTACT_INFO,
  payload: {
    id,
  },
})

export const websiteLinkVisited = id => ({
  type: actionTypes.WEBSITE_LINK_VISITED,
  payload: {
    id,
  },
})

export const addSystemsToCompareList = ids => ({
  type: actionTypes.ADD_SYSTEMS_TO_COMPARE_LIST,
  payload: {
    ids,
  },
})

export const removeSystemsFromCompareList = ids => ({
  type: actionTypes.REMOVE_SYSTEMS_FROM_COMPARE_LIST,
  payload: {
    ids,
  },
})

export const compareSystems = compareIds => ({
  type: actionTypes.COMPARE_SYSTEMS,
  payload: {
    ids: compareIds,
  },
})
